/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import "react-calendar/dist/Calendar.css";

///Images

//Import
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../../context/ThemeContext";
import { fetchmoduleData } from "../../../store/reducers/module";
import { fetchsubmoduleData } from "../../../store/reducers/submodule";
import User from "../table/FilteringTable/User";
import { fetchcompanyData } from "../../../store/reducers/company";
import { fetchuserData } from "../../../store/reducers/user";

const Home = () => {
  const dispatch = useDispatch();
  const { moduleCount } = useSelector((state) => state.module);
  const { submoduleCount } = useSelector((state) => state.submodule);
  const { companyCount } = useSelector((state) => state.company);
  const { userCount } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchmoduleData());
    dispatch(fetchsubmoduleData());
    dispatch(fetchcompanyData());
    dispatch(fetchuserData());
  }, []);

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "dark", label: "dark" });
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-4 col-sm-4">
          <div className="card gradient-1 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{companyCount}</h2>
                <span className="fs-18">Companies</span>
              </div>
              <img src="/img/office.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-4">
          <div className="card gradient-4 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{userCount}</h2>
                <span className="fs-18">Users</span>
              </div>
              <img src="/img/group.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-4">
          <div className="card gradient-2 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{moduleCount}</h2>
                <span className="fs-18">Modules</span>
              </div>
              <img src="/img/employee.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-4">
          <div className="card gradient-3 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{submoduleCount}</h2>
                <span className="fs-18">Sub-Modules</span>
              </div>
              <img src="/img/driver.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
      </div>
      <User />
    </>
  );
};
export default Home;
