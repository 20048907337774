// driverSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/AxiosInstance";

// Create an async thunk to fetch driver data
export const fetchDriverData = createAsyncThunk(
  "driver/fetchDriverData",
  async () => {
    try {
      const response = await axiosInstance.get("driver");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const driverSlice = createSlice({
  name: "driver",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDriverData.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchDriverData.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload;
        state.driverCount = action.payload.length;
      })
      .addCase(fetchDriverData.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default driverSlice.reducer;
