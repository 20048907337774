// companySlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/AxiosInstance";

// Create an async thunk to fetch company data
export const fetchcompanyData = createAsyncThunk(
  "company/fetchcompanyData",
  async () => {
    try {
      const response = await axiosInstance.get("admin/company/view");
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState: {
    data: [],
    companyCount: 0,
    loading: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchcompanyData.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchcompanyData.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload;
        state.companyCount = action.payload.length;
      })
      .addCase(fetchcompanyData.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default companySlice.reducer;
