let direction = "ltr";

export const dezThemeSet = [
  {
    /* Default */ typography: "poppins",
    version: "light",
    layout: "vertical",
    headerBg: "color_1",
    navheaderBg: "color_1",
    sidebarBg: "color_1",
    sidebarStyle: "full",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "full",
    direction: direction,
  },
  {
    /* Demo Theme 1 */ typography: "poppins",
    version: "light",
    layout: "vertical",
    navheaderBg: "color_2",
    headerBg: "color_1",
    sidebarStyle: "modern",
    sidebarBg: "color_2",
    sidebarPosition: "static",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_2",
    direction: direction,
  },
  {
    /* Demo Theme 2 */ typography: "poppins",
    version: "light",
    layout: "vertical",
    navheaderBg: "color_3",
    headerBg: "color_1",
    sidebarStyle: "full",
    sidebarBg: "color_3",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_3",
    direction: direction,
  },
  {
    /* Demo Theme 3 */
    typography: "poppins",
    version: "dark",
    layout: "vertical",
    navheaderBg: "color_5",
    headerBg: "color_1",
    sidebarStyle: "full",
    sidebarBg: "color_5",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_5",
    direction: direction,
  },
  {
    /* Demo Theme 4 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    navheaderBg: "color_9",
    headerBg: "color_9",
    sidebarStyle: "full",
    sidebarBg: "color_1",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_9",
    direction: direction,
  },
  {
    /* Demo Theme 5 */
    typography: "poppins",
    version: "light",
    layout: "horizontal",
    navheaderBg: "color_1",
    headerBg: "color_1",
    sidebarStyle: "full",
    sidebarBg: "color_3",
    sidebarPosition: "static",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_3",
    direction: direction,
  },
  /* {
		
		typography: "poppins",
		version: "dark",
		layout: "vertical",
		navheaderBg: "color_5",
		headerBg: "color_1",
		sidebarStyle: "full",
		sidebarBg: "color_5",
		sidebarPosition: "fixed",
		headerPosition: "fixed",
		containerLayout: "wide",
		primary: "color_5",
		direction: direction
	}, */
];
