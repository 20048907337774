import React, { useContext } from "react";

/// React router dom
import { Route, Routes } from "react-router-dom";

/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";
/// Dashboard
import Home from "./components/Dashboard/Home";

/////Demo pages
//import Demo6 from './components/Dashboard/Demo/Demo6';

/// App

/// Product List

/// Charts

/// Bootstrap

/// Plugins

//Redux

/// Widget

/// Table

/// Form

/// Pages
import { ThemeContext } from "../context/ThemeContext";
import Module from "./components/table/FilteringTable/Module";
import Setting from "./layouts/Setting";
import SubModule from "./components/table/FilteringTable/SubModule";
import Packages from "./components/table/FilteringTable/Packages";
import Company from "./components/table/FilteringTable/Company";
import User from "./components/table/FilteringTable/User";
import Levels from "./components/table/FilteringTable/Levels";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "company/:id", component: User },
    { url: "levels", component: Levels },
    { url: "company", component: Company },
    { url: "module", component: Module },
    { url: "sub-module", component: SubModule },
    { url: "package", component: Packages },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Routes>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  element={<data.component />}
                />
              ))}
            </Routes>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
