/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import "./filtering.css";

import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        type="search"
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Packages = () => {
  const options = [];

  // Loop from 1 to 8 to create options
  for (let i = 1; i <= 8; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  const keys = [
    "plan",
    "package_amount",
    "package_users",
    "package_type",
    "description",
  ];
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const [editshow, setEditShow] = useState(false);
  const onEditShow = () => setEditShow(true);
  const onEditHide = () => setEditShow(false);
  const [loader, setLoader] = useState(false);
  const [fieldvalue, setFieldValue] = useState({
    package_amount: "",
    package_users: "",
  });

  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key.replace(/_/g, " "),
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Recommended",
      Footer: "Recommended",
      accessor: "recommended",
      Cell: ({ value }) => {
        return (
          <div
            className={`text-capitalize 
            ${value === "true" ? "bg-success badge" : "bg-danger badge"}
            `}
          >
            {value}
          </div>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        const DeletePackage = (id) => {
          setLoader(true);
          try {
            axiosInstance
              .get(`admin/package/delete/${id}`)
              .then((response) => {
                if (response.data.status === true) {
                  Package();
                  toast.success(response.data.message);
                  setLoader(false);
                } else {
                  toast.error("SomeThing went Wrong");
                  setLoader(false);
                }
              })
              .catch((errors) => {
                toast.error("SomeThing went Wrong");
                setLoader(false);
              });
          } catch (error) {
            toast.error(error);
          }
        };
        return (
          <>
            <div className="d-flex gap-2">
              <button
                onClick={() => {
                  getPackageID(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-pen" />
              </button>
              <button
                onClick={() => {
                  DeletePackage(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-danger btn-sm rounded`}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const Package = () => {
    setLoader(true);
    axiosInstance
      .get("admin/package/view")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const getPackageID = (id) => {
    setLoader(true);
    axiosInstance
      .get(`admin/package/edit/${id}`)
      .then(function (response) {
        console.log(response);
        if (response?.data.status === true) {
          setFieldValue(response?.data?.data);
          setLoader(false);
          onEditShow();
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const addPackage = () => {
    setLoader(true);
    var formdata = new FormData();
    for (const key in fieldvalue) {
      if (Object.hasOwnProperty.call(fieldvalue, key)) {
        formdata.append(key, fieldvalue[key]);
      }
    }
    axiosInstance
      .post(`admin/package/store`, formdata)
      .then((response) => {
        if (response.data.status) {
          Package();
          onHide();
          toast.success(response.data.message);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  const updatePackage = (id) => {
    setLoader(true);
    var formdata = new FormData();
    for (const key in fieldvalue) {
      if (Object.hasOwnProperty.call(fieldvalue, key)) {
        formdata.append(key, fieldvalue[key]);
      }
    }
    axiosInstance
      .post(`admin/package/update/${id}`, formdata)
      .then((response) => {
        if (response.data.status) {
          Package();
          onEditHide();
          toast.success(response.data.message);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    Package();
  }, []);
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "recommended") {
      if (checked) {
        setFieldValue({ ...fieldvalue, [name]: "true" });
      } else {
        setFieldValue({ ...fieldvalue, [name]: "false" });
      }
    } else setFieldValue({ ...fieldvalue, [name]: value });
  };
  console.log(fieldvalue);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Packages</h4>
            <button className="btn btn-primary float-end" onClick={onShow}>
              Add Package
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Add Modal */}
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>Add Package</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Plan Type</h5>
                <select
                  type={"text"}
                  name={"plan"}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option selected hidden>
                    Select Plan Type
                  </option>
                  <option value={"Pro Plans"}>Pro Plans</option>
                  <option value={"Business Plans"}>Business Plans</option>
                </select>
              </div>

              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Package Type</h5>
                <select
                  type={"text"}
                  name={"package_type"}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option selected hidden>
                    Select Your Package Type....
                  </option>
                  <option value="Monthly">Monthly </option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Package Amount</h5>
                <input
                  type={"number"}
                  name={"package_amount"}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Total Users</h5>
                <select
                  name={"package_users"}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option selected hidden>
                    Select Users....
                  </option>
                  {options}
                </select>
              </div>
              <div className={`mb-3 col-12`}>
                <h5 className="text-capitalize">Description</h5>
                <textarea
                  name={"description"}
                  className="form-control"
                  rows={50}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className={`mb-3 col-12 `}>
                <input
                  name={"recommended"}
                  type="checkbox"
                  onClick={handleInputChange}
                />{" "}
                <label className="text-capitalize">Is Recommended ?</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          <Button variant="success" onClick={addPackage}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add Modal Close */}
      {/* Update Modal Start */}
      <Modal show={editshow} onHide={onEditHide} size="md">
        <Modal.Header closeButton>Package Detail</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Plan Type</h5>
                <select
                  type={"text"}
                  name={"plan"}
                  className="form-control"
                  value={fieldvalue?.plan}
                  onChange={handleInputChange}
                >
                  <option selected hidden>
                    Select Plan Type
                  </option>
                  <option value={"Pro Plans"}>Pro Plans</option>
                  <option value={"Business Plans"}>Business Plans</option>
                </select>
              </div>

              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Package Type</h5>
                <select
                  type={"text"}
                  name={"package_type"}
                  className="form-control"
                  value={fieldvalue?.package_type}
                  onChange={handleInputChange}
                >
                  <option selected hidden>
                    Select Your Package Type....
                  </option>
                  <option value="Monthly">Monthly </option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Package Amount</h5>
                <input
                  type={"number"}
                  name={"package_amount"}
                  className="form-control"
                  value={fieldvalue?.package_amount}
                  onChange={handleInputChange}
                />
              </div>
              <div className={`mb-3 col-6`}>
                <h5 className="text-capitalize">Total Users</h5>
                <select
                  name={"package_users"}
                  className="form-control"
                  value={fieldvalue?.package_users}
                  onChange={handleInputChange}
                >
                  <option selected hidden>
                    Select Users....
                  </option>
                  {options}
                </select>
              </div>
              <div className={`mb-3 col-12`}>
                <h5 className="text-capitalize">Description</h5>
                <textarea
                  name={"description"}
                  className="form-control"
                  rows={50}
                  value={fieldvalue?.description}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className={`mb-3 col-12`}>
                <input
                  name={"recommended"}
                  type="checkbox"
                  checked={fieldvalue?.recommended === "true" ? true : false}
                  onChange={handleInputChange}
                />{" "}
                <label className="text-capitalize">Is Recommended ?</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onEditHide}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => updatePackage(fieldvalue.id)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Modal Close */}
    </>
  );
};
export default Packages;
